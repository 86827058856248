<template>
  <b-row>
    <b-container>
      <b-overlay
        :show="showLoading"
        rounded="lg"
        :opacity="1"
      >
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <strong>{{ msg }}, Aguarde...</strong>
            <b-icon
              icon="stopwatch"
              font-scale="3"
              animation="cylon"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
            <b-spinner
              type="grow"
              variant="primary"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
          </div>
        </template>
        <b-card title="Portal de Pagamentos">
          <validation-observer ref="simpleRules">
            <b-form
              novalidate
              class="needs-validation"
              @submit.prevent
            >
              <b-row>
                <b-col
                  cols="8"
                  sm="8"
                  lg="6"
                  md="6"
                  class="mb-1"
                >
                  <label for="v-ec">Número do EC:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="ec"
                    rules="required"
                  >
                    <b-form-input
                      id="v-ec"
                      v-model="ecNumber"
                      placeholder="Informe o Número do Estabelecimento"
                      :state="
                        errors.length > 0 ? false : ecNumber ? true : null
                      "
                    />
                    <b-form-valid-feedback>
                      Campo Preenchido!
                    </b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      Por favor Preencha o campo
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="ecIsValid"
                  cols="4"
                  sm="4"
                  lg="6"
                  md="6"
                >
                  <b-row>
                    <b-form-checkbox

                      v-model="affilliationIsActive"
                      checked="true"
                      name="check-button"
                      switch
                      style="margin-top: 2rem"
                      disabled
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <p style="margin-top: 2rem">
                      {{ affilliationIsActive ? 'ATIVO VERIPAG' : 'INATIVO VERIPAG' }}
                    </p>
                  </b-row>
                </b-col>

              </b-row>
              <transition name="slide-fade">
                <b-card v-if="ecIsValid">
                  <b-row>
                    <b-col>
                      <h6 class="mb-2">
                        Informações do EC:
                      </h6>
                      <table
                        class="table table-striped table-hover table-bordered"
                      >
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Nome do Estabelecimento:
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ customerName }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Grupo:
                            </td>
                            <td>
                              <b-form-select
                                v-model="customerGroup"
                                :options="groupsOptions"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Email:
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ customerEmail }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              CNPJ:
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ customerTaxIdMasked }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Número de Afiliação:
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ affiliationNumber }}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="canViewEcs">
                            <td class="pr-1">
                              ECs
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                <b-form-tags
                                  v-model="selectedECs"
                                  remove-on-delete
                                  placeholder="Adicione os ECs"
                                  input-id="tags-limit"
                                  class="ec-tag"
                                  disable-add-button
                                />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                  <b-col v-if="hasEcommerce">
                    <b-row>
                      <div class="demo-inline-spacing mb-1">
                        <b-col>
                          <b-card-text class="mb-0">
                            Boleto
                          </b-card-text>
                          <b-form-checkbox
                            v-model="customerHasBankSklip"
                            checked="true"
                            name="check-button"
                            switch
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                          </b-form-checkbox>
                        </b-col>
                        <div>
                          <b-card-text class="mb-0">
                            Venda Direta
                          </b-card-text>
                          <b-form-checkbox
                            v-model="customerHasDirectSale"
                            checked="true"
                            name="check-button"
                            switch
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                          </b-form-checkbox>
                        </div>
                        <b-col>
                          <b-card-text class="mb-0">
                            Recorrência
                          </b-card-text>
                          <b-form-checkbox
                            v-model="customerHasRecurrence"
                            checked="true"
                            name="check-button"
                            switch
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                          </b-form-checkbox>
                        </b-col>

                        <b-col>
                          <b-card-text class="mb-0">
                            Integração
                          </b-card-text>
                          <b-form-checkbox
                            v-model="customerHasIntegration"
                            checked="true"
                            name="check-button"
                            switch
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                          </b-form-checkbox>
                        </b-col>
                      </div>
                    </b-row>
                    <b-col
                      v-if="customerHasBankSklip"
                      md="6"
                      sm="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Número de boletos"
                        label-for="v-qtd-bankslip"
                      >
                        <b-form-input
                          id="v-qtd-bankslip"
                          v-model="bankSlipAmount"
                          type="number"
                          :min="1"
                        />
                      </b-form-group>
                    </b-col>
                  </b-col>

                </b-card>
                <div
                  v-else
                  class="demo-spacing-0"
                >
                  <div class="demo-spacing-0">
                    <b-alert
                      v-height-fade.appear
                      variant="warning"
                      dismissible
                      :show="showDismissibleAlert"
                      class="mb-0"
                      @dismissed="showDismissibleAlert = false"
                    >
                      <h4 class="alert-heading">
                        {{ msgError }}
                      </h4>
                      <div class="alert-body">
                        Atualize a base de dados dos Estabelecimentos.
                      </div>
                    </b-alert>
                  </div>
                </div>
              </transition>
              <b-row>
                <b-col
                  md="4"
                  sm="6"
                  cols="12"
                >
                  <b-button
                    class="info mt-2"
                    variant="primary"
                    block
                    @click="validationForm"
                  >Validar EC</b-button>
                </b-col>
                <b-col
                  md="4"
                  sm="6"
                  cols="12"
                >
                  <b-button
                    class="info mt-2"
                    variant="primary"
                    block
                    :disabled="!affilliationIsActive"
                    @click.prevent="registerEcommerce"
                  >Cadastrar</b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </b-row>
</template>
<script>
import {
    BButton,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BCard,
    BCardText,
    BContainer,
    BForm,
    BFormCheckbox,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormSelect,
    BSpinner,
    BOverlay,
    BFormTags,
    BIcon,
    BAlert,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import { heightFade } from '@core/directives/animations';
import GroupService from '@/service/merchant/mercury/group';
import { checkEc, registerEc } from '@/service/user/userEcommerce';
import 'animate.css';
import { getGroupsOptions } from './GetGroupsOptions';
import permissions from '@/enums/permissions';

export default {
    components: {
        BOverlay,
        BSpinner,
        BIcon,
        ValidationProvider,
        ValidationObserver,
        BFormGroup,
        BButton,
        BFormInput,
        BCol,
        BFormTags,
        BRow,
        BCard,
        BContainer,
        BForm,
        BCardText,
        BFormCheckbox,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BAlert,
        BFormSelect,
    },
    directives: {
        'height-fade': heightFade,
        Ripple,
    },
    data() {
        return {
            selected: [],
            customerHasBankSklip: false,
            customerHasDirectSale: false,
            customerHasRecurrence: false,
            customerHasIntegration: false,
            affiliationNumber: null,
            customerGroup: 1,
            customerEmail: null,
            ecNumber: null,
            customerTaxId: null,
            customerName: null,
            bankSlipAmount: 0,
            permission: [],
            name: '',
            required,
            email,
            showLoading: false,
            ecIsValid: false,
            msg: ' ',
            msgError: ' ',
            showDismissibleAlert: false,
            affilliationIsActive: false,
            affiliationId: null,
            hasEcommerce: '',
            groups: [],
            selectedECs: [],
            permissionsEcommerce: {
                bankslip: [4, 11],
                recurrence: [5, 10],
                directSale: [3, 9],
                integration: [56],
            },
        };
    },
    computed: {
        customerTaxIdMasked() {
            return this.customerTaxId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        },

        groupsOptions() {
            return getGroupsOptions(this.groups);
        },

        canViewEcs() {
            return this.$store.getters['user/user'].data.profile.permissions.includes(permissions.canViewECs);
        },
    },
    watch: {
        customerName() {
            this.bankSlipAmount = 0;
            this.customerHasRecurrence = false;
            this.customerHasDirectSale = false;
            this.customerHasBankSklip = false;
            this.customerHasIntegration = false;
        },

        customerHasDirectSale(value) {
            this.permission = this.doEcommercePermission(this.permissionsEcommerce.directSale, value);
        },

        customerHasRecurrence(value) {
            this.permission = this.doEcommercePermission(this.permissionsEcommerce.recurrence, value);
        },

        customerHasBankSklip(value) {
            this.permission = this.doEcommercePermission(this.permissionsEcommerce.bankslip, value);
        },

        customerHasIntegration(value) {
            this.permission = this.doEcommercePermission(this.permissionsEcommerce.integration, value);
        },
    },

    async mounted() {
        const { data } = await GroupService.getAllGroups();
        this.groups = data.data;
    },
    methods: {
        async validateEc() {
            this.msg = 'Validando Usuário';
            this.showLoading = true;

            this.affiliationId = null;

            const { ecNumber } = this;
            const { status, data: { data } } = await checkEc(ecNumber);

            if (status === 200) {
                const {
                    affiliations, name, email, tax_id,
                } = data;

                const affiliationText = affiliations.reduce((acc, { name, capture_method_id, is_active }, index) => {
                    if (!is_active) return acc;
                    acc += `${name}: ${capture_method_id}`;
                    if (affiliations.length - 1 !== index) {
                        acc += ', ';
                    }

                    return acc;
                }, '');
                this.affiliations = affiliations;
                this.affiliationNumber = affiliationText;

                this.ecIsValid = true;
                this.showDismissibleAlert = false;

                this.customerEmail = email;
                this.customerTaxId = tax_id;
                this.customerName = name;

                const isActive = affiliations.find(affilliation => affilliation.is_active);
                this.affilliationIsActive = !!isActive;

                this.hasEcommerce = (affiliations.some(affilliation => affilliation.name === 'E-COMMERCE'));

                this.showLoading = false;
            } else {
                this.showLoading = false;
                this.error(`O EC ${ecNumber}, não foi Encontrado!`);
                this.ecIsValid = false;
                this.showDismissibleAlert = true;
                this.msgError = `O EC ${ecNumber}, não foi Encontrado!`;
            }
        },
        error(msg) {
            this.$swal({
                icon: 'error',
                text: msg,
            });
        },
        success(msg) {
            this.$swal({
                icon: 'success',
                text: msg,
                timer: 2000,
                showConfirmButton: false,
            });
        },

        async registerEcommerce() {
            this.msg = 'Cadastrando Usuário';

            if (this.permission.length) {
                this.permission = this.permission.filter(item => item !== 14);
            } else {
                this.permission = [14];
            }

            const ecommerceAffiliation = this.affiliations.find(item => item.name === 'E-COMMERCE');

            const userData = {
                ec: this.ecNumber,
                email: this.customerEmail,
                affiliation_id: !ecommerceAffiliation ? null : ecommerceAffiliation.capture_method_id,
                permissions: this.permission,
                bankslip_limit: this.bankSlipAmount,
                group_id: this.customerGroup,
                ecs: this.selectedECs,
            };

            if (this.ecIsValid === true) {
                this.showLoading = true;
                try {
                    const { status, data } = await registerEc(userData);

                    this.showLoading = false;

                    if (status === 200 || status === 201) {
                        this.$swal({
                            icon: 'success',
                            html: `<span>O EC ${this.ecNumber} foi cadastrado com sucesso </span> `,
                            customClass: {
                                confirmButton: 'btn btn-primary btn-modal',
                            },
                            buttonsStyling: false,
                        });
                    } else if (data.errors[0].field === 'email') {
                        this.error('E-mail já cadastrado');
                        this.showLoading = false;
                    } else { this.error('Não foi possível cadastrar o ecommerce'); }
                } catch {
                    this.error('Não foi possível cadastrar o ecommerce');
                    this.showLoading = false;
                }
            }
        },
        async validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.validateEc();
                }
            });
        },
        doEcommercePermission(eCommercePermission, hasPermission) {
            if (hasPermission) {
                return [...this.permission, ...eCommercePermission];
            }
            return this.permission.filter(userPermission => !eCommercePermission.some(permission => permission === userPermission));
        },
    },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/pages/new-client.scss";

</style>
