<template>
  <b-row>
    <b-container>
      <b-card title="Buscar Usuário">
        <b-form-input
          v-model="filterEcNumber"
          placeholder="Informe o Número do EC"
          type="text"
          class="d-inline-block"
        />
      </b-card>
      <b-card>
        <b-table
          :per-page="perPage"
          :current-page="currentPage"
          :fields="tableUsersField"
          :items="tableUsersItems"
          :filter="filterEcNumber"
          striped
          hover
          responsive
          :busy="isLoading"
          :tbody-tr-class="rowRevoked"
          class="text-center"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #cell(is_revoked)="data">
            <div class="text-center text-primary my-2">
              <b-badge
                pill
                :variant="data.item.is_revoked ? 'light-danger' : 'light-success'"
              >
                {{ data.item.is_revoked ? 'SIM' : 'NÃO' }}
              </b-badge>
            </div>
          </template>
          <template #cell(bank_slip)="data">
            <span
              v-for="item in data"
              :key="item.id"
            >
              <span v-if="item.bank_slip == 4">
                <b-badge
                  pill
                  variant="light-success"
                > Hablitado </b-badge>
              </span>
              <span v-if="item.bank_slip == 0">
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Não Habilitado
                </b-badge></span>
            </span>
          </template>
          <template #cell(recurrence)="data">
            <span
              v-for="item in data"
              :key="item.id"
            >
              <span v-if="item.recurrence == 5">
                <b-badge
                  pill
                  variant="light-success"
                  class="center"
                >
                  Hablitado
                </b-badge>
              </span>
              <span v-if="item.recurrence == 0">
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Não Habilitado
                </b-badge></span>
            </span>
          </template>

          <template #cell(direct_sale)="data">
            <span
              v-for="item in data"
              :key="item.id"
            >
              <span v-if="item.direct_sale == 3">
                <b-badge
                  pill
                  variant="light-success"
                  class="center"
                >
                  Hablitado
                </b-badge>
              </span>
              <span v-if="item.direct_sale == 0">
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Não Habilitado
                </b-badge></span>
            </span>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'edit-ecommerce', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="modalRevoked(data)"
              >
                <feather-icon :icon="data.item.is_revoked ? 'UserIcon' : 'XCircleIcon'" />
                <span class="align-middle ml-50">{{ data.item.is_revoked ? "Desrevogar" : "Revogar" }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-if="!isLoading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-card>
    </b-container>
  </b-row>
</template>

<script>
import {
    BRow,
    BContainer,
    BTable,
    BPagination,
    BCard,
    BBadge,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
} from 'bootstrap-vue';
import { getAllUsers } from '@/service/user/userEcommerce';

import { revokeUser } from '@/service/user/userBackOffice';

export default {
    components: {
        BRow,
        BContainer,
        BTable,
        BPagination,
        BCard,
        BBadge,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BSpinner,
    },
    data() {
        return {
            perPage: 8,
            totalRows: null,
            filterEcNumber: null,
            currentPage: 1,
            isLoading: false,
            tableUsersField: [
                { key: 'veripag_id', label: 'Número do EC' },
                { key: 'affiliation_id', label: 'Afiliação' },
                { key: 'email', label: 'E-mail' },
                { key: 'bank_slip', label: 'Boleto' },
                { key: 'recurrence', label: 'Recorrência' },
                { key: 'direct_sale', label: 'Venda Direta' },
                { key: 'is_revoked', label: 'revogado?' },
                { key: 'actions', label: 'Ações' },
            ],
            tableUsersItems: null,
        };
    },
    async mounted() {
        await this.listAllUsersEc();
    },
    methods: {
        rowRevoked(item) {
            return item?.isRevoked === 1 ? 'is-revoked' : '';
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async pagination(arrayLenght) {
            this.totalRows = arrayLenght;
        },

        responseModal(success, revoke) {
            if (success) {
                this.$swal({
                    icon: 'success',
                    html: revoke ? '<span>O usuário foi desrevogado com sucesso </span>' : '<span>O usuário foi revogado com sucesso </span>',
                });
            } else {
                this.$swal({
                    icon: 'error',
                    html: '<span> Não foi possível revogar o usuário </span>',
                });
            }
        },

        modalRevoked({ item }) {
            this.$bvModal
                .msgBoxConfirm(`Deseja realmente ${item.is_revoked ? 'desrevogar' : 'revogar'} o usuário ${item.veripag_id}?`, {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) { this.revokeUser(item); }
                });
        },

        async revokeUser({ id }) {
            this.isLoading = true;
            const response = await revokeUser(id);
            this.responseModal(response === 200);

            if (response === 200) {
                await this.listAllUsersEc();
            }
            this.isLoading = false;
        },
        async listAllUsersEc() {
            this.isLoading = true;
            const { data } = await getAllUsers();
            const { users } = data;

            this.pagination(users.length);

            const usersEcommerce = users.map(userEcommerce => {
                const bankSlipActive = userEcommerce.userPermission.some(user => user.permission_id == 4) ? 4 : 0;
                const directSaleActive = userEcommerce.userPermission.some(user => user.permission_id == 3) ? 3 : 0;
                const recurrenceActive = userEcommerce.userPermission.some(user => user.permission_id == 5) ? 5 : 0;

                return ({
                    id: userEcommerce.id,
                    is_revoked: userEcommerce.is_revoked,
                    veripag_id: userEcommerce.veripag_id,
                    affiliation_id: userEcommerce.affiliation_id,
                    email: userEcommerce.email,
                    bank_slip: bankSlipActive,
                    recurrence: recurrenceActive,
                    direct_sale: directSaleActive,
                });
            });

            this.tableUsersItems = usersEcommerce;
            this.isLoading = false;
        },
    },
};
</script>
<style lang="scss">
  .is-revoked {
    background-color: rgba(0, 0, 0 , .09) !important;
    &:hover{
      background-color: rgba(0, 0, 0 , .11) !important;
    }
  }
</style>
